<template>
  <div>
    <b-card>
      <b-table
        :items="getFeedbackReports"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :fields="tableColumns"
        responsive
        show-empty
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('update','global-ScrappedEventController')"
              @click="toggleReportStatus($route.params.id,item)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> {{ item.readAt ? 'Unread' : 'Read' }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import paginationData from '@/common/compositions/common/paginationData'
import communityEvent from '@/common/compositions/CommunityEvents/communityEventApi'

export default {
  name: 'FeedbackReports',
  components: { Pagination },
  data() {
    return {
      tableColumns: [
        { key: 'content', label: 'feedback' },
        { key: 'user.profile', label: 'username', formatter: v => `${v.first_name} ${v.last_name}` },
        { key: 'user.email', label: 'email' },
        { key: 'sentAt', label: 'Sent Date Time' },
        { key: 'readAt', label: 'status', formatter: v => (v ? 'Read' : 'Unread') },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { listFeedbackReports, toggleReportStatus } = communityEvent()
    const { successfulOperationAlert } = handleAlerts()
    return {
      toggleReportStatus, listFeedbackReports, pagination, successfulOperationAlert,
    }
  },
  methods: {
    getFeedbackReports() {
      return this.listFeedbackReports(this.$route.params.id).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      }).catch(() => [])
    },
  },
}
</script>
<style lang="scss">

</style>
